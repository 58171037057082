import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `jens`, `raaby`]} />
    <h1>Hi people</h1>
    <p>Welcome to my Work In Progress site.</p>
    <p>
      Still working on the design and content. You should probably go to my{" "}
      <a href="https://jens.raaby.co.uk">existing website</a> if you want to
      find any content.
    </p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
  </Layout>
)

export default IndexPage
